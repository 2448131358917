<template>
    <div :style=" {'margin-top':'0px',width:'1230px','margin-left':'auto','margin-right':'auto'} " >
        <div class="title">
           <Icon type="md-checkmark-circle-outline" style="font-size:24px;font-weight:700;margin-left:6px;"/> 恭喜您, 订单已提交成功！请及时付款, 以便订单尽快处理 !
        </div>
        <div style="margin-top:20px;font-weight:500 ;font-size:1.1em; text-align:left;display:flex  ">
           <div style="color:#606266;font-size:14px; margin-right:100px;">订单编号：{{ordno}}</div>
           <el-link  style="color:#409eff" ><i class="el-icon-search"></i>查看详情</el-link>
        </div>
        <div style="margin-top:20px;font-weight:500 ;font-size:1.1em; text-align:left;display:flex  ">
           <div style="color:#606266;font-size:14px; margin-right:20px;">应付金额：<span style="color:red;font-weight:700;font-size:16px;">¥ {{totalAmt}}</span></div>
        </div>
        <div style="margin-top:20px;font-weight:500 ;font-size:1.1em; text-align:left;display:flex  ">
           <div style="color:#606266;font-size:14px; margin-right:100px;">支付方式：<span style="color:red;font-weight:700;font-size:16px;"> {{paymth}}</span></div>
           <el-link  @click="payChange" style="color:#409eff" > ¥ 更改支付方式</el-link>
        </div>
        <div style="margin-top:20px; ">
            <table  >
                <tr style="line-height:40px;height:40px;background:#e8eaec">
                    <td  class="rowdiv">
                       <span style="margin-left:10px; "> 温馨提示：请复制下方汇款账户至第三方网银支付平台或线下银行柜台付款</span>
                    </td>
                </tr>
            </table>
        </div>
        <div style="margin-top:20px;font-weight:500 ;font-size:1.1em; text-align:left;display:flex  ">
           <div style="color:#606266;font-size:14px; margin-right:10px;display:flex;">汇款账户：</div>
            <div style="color:#606266;font-weight:700; font-size:14px;display:flex;flex-direction:column"> 
                <div style="display:flex;margin-top:-4px;">
                    <div class="bankrow" >开户银行名称：</div>
                    <div style="line-height:30px;">{{supArr[0].BANKNM}}</div>
                </div>
                <div style="display:flex">
                    <div class="bankrow" >开户行地址：</div>
                    <div style="line-height:30px;">{{supArr[0].BANKADDR}}</div>
                </div>
                <div style="display:flex">
                    <div class="bankrow" >账户名称：</div>
                    <div style="line-height:30px;">{{supArr[0].PAYNM}}</div>
                </div>
                <div style="display:flex">
                    <div class="bankrow" >银行账户：</div>
                    <div style="line-height:30px;">{{supArr[0].PAYNO}}</div>
                </div>
                <div style="font-size:14px;color:#ed4014;line-height:40px;">请您汇款时在"用途栏"中注明您的订单号及汇款金额，以便于我们及时确认付款</div>
                <div style="margin-top:20px;"><el-button size="medium" type="primary" @click="uploadBill" >
                    <Icon type="ios-cloud-upload-outline" style="font-size:20px;margin-right:5px;"/>上传水单</el-button>
                </div>
            </div>
            
        </div>
        <Modal v-model="upModel" :mask-closable="false" :closable="false" :styles="{left:'10px',top:'10px'}"  scrollable   width="600px"  >
            <el-form ref="upForm" :model="formData" size="small" label-width="110px"  label-position="right" inline > 
                <el-form-item  label="金额"  >               
                    <el-input-number class="row-width"   v-model="formData.PAYAMT" :controls="false"></el-input-number> 
                </el-form-item>
                <el-form-item  label="公司名称"  >               
                    <el-input class="row-width"   v-model="formData.CORPNM"  ></el-input> 
                </el-form-item>
                <el-form-item  label="币种"   >               
                    <el-select class="row-width"   v-model="formData.CRCY"  >
                        <el-option label="人民币" value="RMB"></el-option>
                        <el-option label="美元" value="USD"></el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item  label="备注" >               
                    <el-input class="row-width"   v-model="formData.PAYRMK"  ></el-input> 
                </el-form-item>
                <el-form-item   > 
                    <div style="display:flex;" >
                        <div style="margin-left:110px;" >
                            <Upload
                                ref="upload"
                                name="upfile"
                                :data="uploadData"
                                :show-upload-list="false"
                                :before-upload="beforeImgFile"
                                :on-success="successImgFile"
                                :accept="Accept"                           
                                :format="['jpg','jpeg','png','gif','webp' ]"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                type="drag"
                                :action="uploadUrl"
                                style="display: inline-block;width:200px; ">                           
                                <div style="padding: 1px 0;width:200px;height:150px;">
                                    <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;"></el-image> 
                                    <p style="color:#515a6e;margin-top:5px;">将水单拖动此处或点击上传</p>
                                </div>                                                       
                            </Upload> 
                        </div>
                        <div style="display:flex;margin-left:30px;flex-direction:column"  >
                            <el-image fit="fill"  style="width:150px;height:125px;margin-right:30px;" :src="formData.IMGPATH"></el-image>
                            <div style="height:25px;background:#e8eaec;width:150px; display:flex;justify-content:space-between">
                                <el-link :underline="false" @click="imgPreview(formData.IMGPATH)"><Icon type="ios-search" /> 预览</el-link>
                            </div>
                        </div> 
                        <div>
                            <el-image-viewer
                            v-if="showViewer"
                            :on-close="closeViewer"
                            :url-list="listurl"
                            style="width: 70%; height: 70%; margin-left: 10%; margin-top: 5%"/>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" style="height:24px;">
                <Button type="primary" plain   @click="upModel=false" style="margin-right:25px;">取消</Button> 
                <Button type="primary"  @click="upSave" style="margin-right:25px;">保存</Button>            
            </div>
            <div slot="header" style="height:10px; "  >
                 <span style="font-weight:600;font-size:14px;color:#ccc"> 上传水单  </span>
            </div> 
        </Modal>
    </div>
</template>
<script>
import {getBaseData } from '../../api/user'; 
import { getDate } from '../../api/Select'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer' 
export default {   
    data () {
        return {
            ordno:'' , //订单编码
            totalAmt:'', //应付金额
            paymth:'',//支付方式
            supArr:[{PAYNM:'',BANKNM:'',PAYNO:'',BANKADDR:''}],
            upModel:false,
            listurl:[],//放大的图片
            uploadUrl:this.$store.state.baseUrl +'auth/login/uploadImg',
            fileName:'',
            showViewer:false,
            Accept: "png,jpg,jpeg,mp4", //上传文件格式限制s
            uploadData:{}, //上传数据
            formData:{
                PAYAMT:0, //金额
                CORPNM:'', //公司名称
                CRCY:'', //币种
                PAYRMK :'', //备注
                IMGPATH:'',
            },
            ruleValidate:{
                 'AMT': [{ required: true, message: '请输入金额', trigger: 'blur' }],
            },
        }
    },
    mounted () {
        //收款信息
        getBaseData(this.$store.state.nologinUrl, 'idseq','38857','','','v_bs_sup','').then(res=>{
            this.supArr=res.data.result
        }) 
        
    },
    created () {
        let idno =this.$route.query.p_idno
        let amt =this.$route.query.p_amt
        let term =this.$route.query.p_term
        if (idno){
            this.setOrdno(idno,amt,term ) 
        }  
    },
    components: {
        ElImageViewer,  
    },
    methods: {
        closeViewer(){ //关闭图片预览
            this.showViewer = false
        },
        //图片预览
        imgPreview(path){
             this.showViewer=true
             this.listurl=[path]
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.$Message.error(file.name + ' 文件太大, 不能超出2M' )
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.$Message.error('文件格式错误,'+file.name + ' 是不正确, 请选择 jpg or png' )
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            let v_filename='/assets/basedict/'+this.fileName+file.name.substring(file.name.indexOf('.'))
            Object.assign(this.formData,{IMGPATH:v_filename})       
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {              
            this.fileName= getDate('year')+'-BILL'     
            this.uploadData = {
                code: this.fileName ,
                p_table:'ORD',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        //上传水单
        uploadBill(){
            if (this.ordno){
                this.upModel =true
                getBaseData(this.$store.state.nologinUrl, 'IDNO',this.ordno,'','','v_ord','').then(res=>{
                    this.formData=res.data.result[0]  
                }) 
            }else{
                this.$Message.error('订单编号不能为空')
            }
        },
        // 保存水单
        upSave(){
            if (!this.formData.IMGPATH){
                this.$Message.error('请上传水单')
            }else{
                if (this.formData.PAYAMT>0){
                    let updcol=' set payamt='+this.formData.PAYAMT+',corpnm=\''+this.formData.CORPNM+'\',crcy=\''+this.formData.CRCY+'\',PAYRMK=\''+this.formData.PAYRMK+'\',IMGPATH=\''+this.formData.IMGPATH+'\',stfg=\'pay\''
                    this.$axios({
                        method:'post',
                        url: this.$store.state.baseUrl+'auth/login/updateRec' ,
                        data: {p_key:'idno',p_value:this.ordno, p_table:'ord',p_cond:updcol},
                    }).then(res =>{
                        if (res.data.code==='200'){
                            this.upModel =false 
                        }else{
                            this.$alert(res.data.result, '提示', {
                                confirmButtonText: '确定',
                                showClose:false,
                                type:'info'
                            })
                        }
                    }) 
                    
                }else{
                    this.$Message.error('请输入大于0的金额')
                }
            }
        },
        setOrdno(ordno,amt,paymth){
            this.ordno=ordno
            this.totalAmt = Number(amt).toFixed(2) //保留2位小数
            this.paymth =paymth
        },
        //变更付款方式
        payChange(){
            let routeUrl = this.$router.resolve({
                path: "/member/basic_info",
                query: {lang:this.$store.state.lang,pid:'/member/poord' },
            });
            window.open(routeUrl.href, '_self'); 
        }
    }
}
</script>
<style scoped>
    .row-width{
        width: 300px !important;
    }
    .title{
        margin-top:20px;
        font-weight:600 ;
        font-size:1.3em;
        color:#67C23A;
        text-align:left;
        border:2px solid #67C23A ;
        line-height:50px;
    }
    .rowdiv{
        color:#413f3d;
        font-weight:600 ;
        font-size:1.2em;
        width:1230px;
        text-align:left;
        border-left:3px solid #409EFF;
    }
    .bankrow{
        width:120px;
        line-height:30px;
    }
</style>